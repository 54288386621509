import { useCallback } from 'react';
import cn from 'classnames';
import { Icon } from '@dealroadshow/uikit';

import Image from '@/Framework/Router/Next/Image';
import SlickSlider from '@/Framework/UI/Molecules/SlickSlider';
import { IClassNames, ITab } from '@/Framework/UI/Pages/Landing/Sections/TabsSlider/interface';

import styles from './tabsSlider.scss';

interface IProps {
  content: ITab[],
  classNames?: IClassNames,
  sliderId: string,
  descriptionAboveDots?: React.ReactNode,
  isReversedPosition?: boolean,
  classNamesDots?: string,
}

const imageSliderSettings = {
  dots: true,
  fade: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesPerRow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const TabsSlider = ({
  content,
  classNames = {},
  sliderId,
  descriptionAboveDots,
  isReversedPosition = false,
}: IProps) => {
  const customDots = useCallback((dots) => (
    <div>
      { descriptionAboveDots }
      <ul className={ styles.dotsWrapper }>
        { dots.map((item, index) => {
          const {
            key,
            icon,
          } = content[index] ?? {};
          return (
            <li
              key={ `dot${ key }` }
              data-test={ `${ key }SlideButton` }
              className={ cn(styles.dot, item.props.className) }
            >
              <div
                { ...item.props.children.props }
                className={ styles.dotButton }
              >
                <span className={ cn(styles.dotNumber, {
                [styles.iconWrapper]: icon,
              }) }
                >
                  { icon ? <Icon type={ icon } /> : +item.key + 1 }
                </span>
                <div className={ styles.dotText }>
                  <h4 className={ styles.title } data-test={ `${ key }SlideTitle` }>{ content[item.key].title }</h4>
                  <div
                    className={ styles.description }
                    data-test={ `${ key }SlideDescription` }
                  >
                    { content[item.key].description }
                  </div>
                </div>
              </div>
            </li>
          );
        }) }
      </ul>
    </div>
  ), [content]);

  // @ts-ignore
  return (
    <div
      className={ cn(styles.slidersWrapper, classNames.slidersWrapper, {
        [styles.slidersWrapperIsReversed]: isReversedPosition,
        [styles.slidersWrapperWithDotsDescription]: !!descriptionAboveDots,
      }) }
    >
      <SlickSlider
        key={ sliderId }
        dotsClass={ cn(styles.dotsList, classNames.dotsListClassName) }
        { ...imageSliderSettings }
        // @ts-ignore
        appendDots={ customDots }
      >
        { content.map((slide) => (
          <div key={ slide.key } data-test={ `${ slide.key }SlideContent` }>
            <Image
              className={ cn(styles.img, classNames.imgClassName) }
              src={ slide.img.src }
              width={ slide.img.width }
              height={ slide.img.height }
              placeholder="empty"
              alt="Tab image"
            />
            { slide.imgLabel && (
              <p className={ cn(styles.imgLabel, classNames.imgLabelClassName) }>{ slide.imgLabel }</p>
            ) }
            <div className={ styles.slideText }>
              <h4 className={ cn(styles.title, classNames.titleClassName) }>
                { slide.title }
              </h4>
              <div className={ styles.description }>{ slide.description }</div>
            </div>
          </div>
        )) }
      </SlickSlider>
    </div>
  );
};

export default TabsSlider;
